import React from 'react';
import { Link } from 'gatsby';
import Slide from 'react-reveal/Slide';

import Layout from 'src/components/Layout';
import TestImage1 from 'src/images/test-image-1.jpg';
import TestImage2 from 'src/images/test-image-2.jpg';
import TestImage3 from 'src/images/test-image-3.jpg';
import FarmProduct1 from 'src/images/farm-product-1.jpg';
import FarmProduct2 from 'src/images/farm-product-2.jpg';
import FarmImage from 'src/images/simplicity-farms.jpg';
import StripeImage1 from 'src/images/logo-stripe.png';
import GastbyIcon from 'src/images/gatsby-icon.png';
import TailwindIcon from 'src/images/tailwind.png';
import WarelmaLogo from 'src/images/warelma/logo.png';
import SimpleMap from 'src/components/SimpleMap';
import ContactSection from "src/components/ContactSection";
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';

const IndexPage = ({data}) => {
  const homepage = data.allPrismicHomepage.nodes[0].data;
  return (
  <Layout>
    {console.log(data)}
    <SEO title="Home"/>

    <div className="p-2 mt-4 sm:m-auto sm:py-12">
      <div className="flex flex-col pb-10 border-b-2 lg:flex-row items-center">
        <div className="flex w-full justify-center mb-5 lg:justify-start lg:w-1/2">
          <div className="bg-dots">
            <div className="z-10 max-w-md mt-6 rounded-full shadow-2xl p-16 warelma-logo">

              <img
                  alt="card img"
                  className="rounded-t"
                  src={WarelmaLogo}
              />

            </div>
            <Slide left>
              <div className="w-64 pt-2 m-auto">
                <div className="text-3xl text-primary text-left leading-tight h-3">“</div>
                <p className="text-xl px-5 text-gray-600 text-center">
                  {homepage.quote.text}
                </p>
                <div className="text-3xl text-primary text-right leading-tight h-3 -mt-10">”</div>
              </div>
            </Slide>

          </div>
        </div>
        <Slide right>
          <div className="flex flex-col  md:justify-end  max-w-md lg:w-1/2 md:ml-3">



            <div className="mt-5 md:mt-0 text-2xl font-black uppercase md:text-5xl text-primary leading-tight">
              {homepage.title.text}
            </div>
            <div className="mt-4 text-xl">
              <div dangerouslySetInnerHTML={{__html: homepage.subtitle_1.html}}/>

            </div>
            <div className="mt-4 text-xl">
              <div dangerouslySetInnerHTML={{__html: homepage.subtitle_2.html}}/>

            </div>

            <div className="h-16 my-5 mt-10">
              <Link
                  to="/referenties"
                  className="px-4 py-2 text-white rounded shadow-sm sm:top-4 hover:no-underline bg-secondary hover:bg-grey shadow-md"
              >
                <span className="">Bekijk referenties</span>
              </Link>
            </div>

          </div>
        </Slide>
        {/* Hero Section */}
      </div>
      <div className="container pt-2 pb-6 mx-auto border-b-2">
        <h1 className="py-4 text-center font-slim text-primary">Over ons</h1>
        <div className="flex justify-center px-1 md:px-6 my-4">
          <div className="md:w-60 md:w-full md:flex-row sm:flex-col">

            <div className="w-full p-5 bg-white">
              <div className="mb-4 text-center sm:px-4">
                <p className="mb-4 text-gray-700">

                    <div dangerouslySetInnerHTML={{__html: homepage.over_ons.html}}/>

                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Our Products Section */}
      <div className="container pt-2 pb-6 mx-auto mt-2 border-b-4 sm:block" id={"onze-diensten"}>
        <h1 className="py-4 text-center font-slim text-primary">Onze diensten</h1>
        <div className="flex flex-wrap">
          <Slide left>
            <div className="w-full md:w-1/2">
              <div className={"shadow p-4 rounded mx-2 my-2"}>
                <h2 className={"text-xl text-secondary mt-2 mb-5 font-black"}>Zandstralen</h2>
                <p>
                  <div dangerouslySetInnerHTML={{__html: homepage.text_service_1.html}}/>
                </p>
              </div>
            </div>

          </Slide>
          <Slide right>
            <div className="w-full md:w-1/2">
              <div className={"shadow p-4 rounded  mx-2 my-2"}>
                <h2 className={"text-xl text-secondary mt-2 mb-5 font-black"}>Luchtgommen</h2>
                <p>
                  <div dangerouslySetInnerHTML={{__html: homepage.text_service_2.html}}/>
                </p>
              </div>
            </div>
          </Slide>
        </div>

        <div className="flex w-full items-center justify-center mt-5">
          <Link
              to="/referenties"
              className="px-4 py-2 text-white rounded shadow-sm sm:top-4 hover:no-underline bg-secondary hover:bg-grey shadow-md"
          >
            <span className="">Bekijk referenties</span>
          </Link>
        </div>

      </div>

      <div className="my-20">
        <ContactSection/>

      </div>

    </div>
  </Layout>)
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
      allPrismicHomepage {
    nodes {
      data {
        over_ons {
          html
        }
        quote {
          text
        }
        subtitle_1 {
          html
        }
        subtitle_2 {
          html
        }
        text_service_1 {
          html
        }
        text_service_2 {
          html
        }
        title {
          text
        }
      }
    }
  }
  }
`