import React from "react";
import ContactBlock from "src/components/ContactBlock";
import SimpleMap from "./SimpleMap";

export default ({}) => {
    return (
        <div className="flex flex-row-reverse md:flex-row   flex-wrap items-center justify-center mb-5 bg-gray-100">
            <div className="w-full md:w-1/2">
                <SimpleMap/>
            </div>
            <div className="w-full pt-5 md:pt-0 md:w-1/2 px-5">
                <ContactBlock/>
            </div>
        </div>
    )
}